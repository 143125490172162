*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}


.App img{
    height: 100px;
    width:  150px;
  
}
nav{
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: fixed; */
}
ul{
    list-style: none;
    display: flex;
    margin-top: -100px;
    margin-left: 160px;
    background-color: black;
    padding: 40px;
    width: 1370px;
}
li{
    margin-left: 100px;
    font-size: 20px;
}
link{
    text-decoration: none;
    color: black;
}
a{
    text-decoration: none;
    color:white
}
